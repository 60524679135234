<!-- 往届学生信息 -- 列表 -->
<template>
  <div>
    <list-template
        :loading="loading"
        :search-config="searchConfig"
        @onSearch="onSearch"
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        :current-page="page"
        @onChangePage="handleCurrentChange"
        @onHandle="handleEdit"
    ></list-template>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      loading: false,
      search:{},
      searchConfig:[
        {
          prop:"student_name",
          tag: 'input',
          placeholder:"搜索学生姓名",
        },
        {
          prop:"school_id",
          tag: 'select',
          placeholder:"筛选往届校区",
          label: 'school_name',
          value: 'id',
          options: [],
        },
        {
          prop:"grade_id",
          tag: 'select',
          placeholder:"筛选年级",
          label: 'name',
          value: 'id',
          options: [],
        },
      ],
      tableData: [],
      //总条数
      total: 0,
      pageCount: 0,

      tableConfig:[
        {prop:"student_name", label:"学生姓名"},
        {prop:"student_no", label:"学号"},
        {prop:"sex", label:"性别"},
        {prop:"class_name",label:"行政班"},
        {prop:"grade_name", label:"年级"},
        {prop:"school_name", label:"往届校区"},
        {
          prop:"handle",
          label:"操作",
          width: "180rem",
          handle: true,
          buttons: [{ type:"view", text:"查看" }]
        },
      ],
    }
  },
  created() {
    this.$store.commit("setPage",1);
    this.getData()
    this.getSchool()
  },
  activated() {
    this.getData()
  },
  computed:{
    ...mapState(["page"])
  },
  methods: {
    getData(){
      let params = {...this.search, page: this.page}
      this.$_axios2('/api/student-rereading/index/list',{params}).then(res => {
        // console.log(res)
        let data = res.data.data
        this.tableData = data.list
        this.total = data.page.total
      }).finally(() => {
        this.loading = false
      })
    },

    getSchool() {
      this.$_axios2("/api/student-rereading/index/school-grade").then(res => {
        let {data} = res.data;
        this.searchConfig[1].options = data.school;
        this.searchConfig[2].options = data.grade;

        this.searchConfig[1].options.unshift({id: "0",school_name: "全部"})
        this.searchConfig[2].options.unshift({id: "0",name: "全部"})
      })
    },

    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit('setPage', e)
      this.getData()
    },

    onSearch(val){
      this.loading = true;
      this.$store.commit('setPage', 1);
      this.search = val;
      this.getData(val)
    },

    handleEdit(row) {
      this.$router.push('./details?id=' + row.id)
    },

    // 当前页改变触发
    handleCurrentChange(val) {
      //当前页赋值给currentPage
      this.$store.commit("setPage",val);
      //拉取数据
      this.getData()
    },


  },
}
</script>

<style lang="scss" scoped>
.role-list {
  padding-top: 24rem;
}
.button {
  margin-left: 34rem;
  margin-bottom: 30rem;
  box-sizing: border-box;
  width: 90rem;
  height: 34rem;
  opacity: 1;
  border-radius: 4rem;
}
.table {
  margin-left: 32rem;
}


::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
  text-align: left;
  font-size: 14rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  height: 48rem;
  box-sizing: border-box;
  color: #333333;
}
.header {
  position: fixed;
  top: 120rem;
  left: 300rem;
  font-size: 16rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 24rem;
  color: #333333;
  letter-spacing: 3.5rem;
}
.el-button--text {
  margin-right: 40rem;
  font-size: 14rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: #3491fa;
}
</style>
